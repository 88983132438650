<template>
  <div class="user-card card">
    <div class="p-3 card-body">
      <div class="d-flex mt-n2">
        <div
          class="avatar avatar-xl bg-gradient-dark border-radius-xl mt-n4 overflow-hidden
                 flex-shrink-0"
        >
          <router-link
            :to="{
              name: 'edit-app-user',
              params: {
                id,
              }
            }"
            class="user-card__link stretched-link w-100 h-100"
          >
            <img
              v-if="image"
              :src="image"
              :alt="`Profielfoto ${name}`"
              class="user-card__image w-100 h-100"
            />
            <i
              v-else
              class="d-block material-icons text-secondary fs-2"
            >
              person
            </i>
          </router-link>
        </div>
        <div class="my-auto ms-3">
          <h6 class="mb-0">{{ name }}</h6>
          <p class="text-xs mb-0">
            {{ profession }}
            <template v-if="profession && companyName">
            /
            </template>
            {{ companyName ?? " " }}
          </p>

          <div
            class="d-flex gap-2 mt-2"
          >
            <material-badge
              v-if="isAdmin"
              size="sm"
              color="warning"
            >
              Admin
            </material-badge>

            <material-badge
              v-if="boardMemberFunction"
              size="sm"
              color="secondary"
            >
              {{ boardMemberFunction }}
            </material-badge>

            <material-badge
              v-if="isActivated"
              size="sm"
              color="success"
            >
              Actief
            </material-badge>
          </div>
        </div>
        <div class="ms-auto">
          <dropdown-menu
            :items="[
              {
                handler: edit,
                name: 'Bewerken',
              },
              {
                handler: sendAppInvite,
                name: 'Verstuur app-uitnodiging',
              },
              {
                handler: remove,
                name: 'Verwijderen',
              },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { sendInvitation } from '@/api/providers/users'
import { remove } from '@/helpers/users';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import MaterialBadge from '@/components/MaterialBadge.vue';

export default {
  name: 'UserCard',

  components: {
    DropdownMenu,
    MaterialBadge,
  },

  props: {
    boardMemberFunction: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
    isActivated: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    profession: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'remove',
  ],

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    edit() {
      this.$router.push({
        name: 'edit-app-user',
        params: {
          id: this.id
        },
      });
    },

    remove() {
      remove(this.id, this.name)
        .then(() => {
          this.$emit('remove');
        })
        .catch((error) => error.default());
    },

    sendAppInvite() {
      sendInvitation(this.id)
        .then(() => {
          this.addNotification({
            description: `Er is een app-uitnodiging verzonden naar ${this.name}.`,
            icon: {
              component: 'mark_email_read',
            },
          });
        })
        .catch((error) => error.default());
    },
  },
};
</script>

<style scoped lang="scss">
.user-card {
  height: 100%;

  &__link {
    display: grid;
    place-content: center;
  }

  &__image {
    object-fit: cover;
  }
}
</style>
